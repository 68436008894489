import bdkImage from "../../images/ImagesProjetIllustration/bdk.jpg"
import biereCulteImage from "../../images/ImagesProjetIllustration/biereCulte.jpg"
import siwegoImage from "../../images/ImagesProjetIllustration/siwego.jpg"
import diffuzImage from "../../images/ImagesProjetIllustration/diffuz.jpg"
import maisonDuMondeImage from "../../images/ImagesProjetIllustration/maisonDuMonde.jpg"
import thaiThaeImage from "../../images/ImagesProjetIllustration/thaiThae.jpg"

import bdkMaquette from "../../images/ImagesProjetMaquettes/BDKMaquette.jpg"
import biereCulteMaquette from "../../images/ImagesProjetMaquettes/biereCulteMaquette.jpg"
import diffuzMaquette from "../../images/ImagesProjetMaquettes/diffuzMaquette.jpg"
import maisonDuMondeMaquette from "../../images/ImagesProjetMaquettes/maisonDuMondeMaquette.jpg"
import maisonDuMondeMaquettePlus from "../../images/ImagesProjetMaquettes/maisonDuMondeMaquettePlus.jpg"
import ThaiThaeMaquette from "../../images/ImagesProjetMaquettes/ThaiThaeMaquette.jpg"

export interface Projects {
    id: number,
    title: string,
    subtitle: string,
    description: string,
    link: string,
    image: string
}

export interface ProjectsDetails {
    id: number,
    title: string,
    subtitle: string,
    description: string,
    linkWeb: string,
    linkMobile: string,
    imageDescription: string,
    imageMaquette: string,
    imageMaquettePlus: string
}

export interface ExperiencesDetails {
    id: number,
    title: string,
    description: string,
    missions: Array<string>,
}

export const PROJECTS: Projects[] = [
    {
        id: 0,
        title: "BDK Parfums",
        subtitle: "Refonte du site internet",
        description: "BDK Parfums est une maison de parfumeur créateur indépendante basée dans le quartier du Palais Royal à Paris. J’étais chargé de proposer de nouvelles interfaces à l’image de la marque. ",
        link: "/bdk",
        image: bdkImage
    },
    {
        id: 1,
        title: "Bières cultes",
        subtitle: "Refonte du site internet",
        description: "Bières Cultes est une entreprise possédant des magasins de bières artisanales basées à Paris où les clients peuvent consommer sur place. Je devais proposer une nouvelle interface web et transformer le site actuel en un site e-commerce.",
        link: "/biereCulte",
        image: biereCulteImage
    },
    {
        id: 2,
        title: "Si We Go",
        subtitle: "Application mobile",
        description: "Si We Go est une application qui sensibilise au stress dans le milieu de travail en proposant divers exercices qui amélioreront le développement personnel. Cette application est un projet d’école de fin d’année.",
        link: "/siwego",
        image: siwegoImage
    },
    {
        id: 3,
        title: "Diffuz",
        subtitle: "Application mobile",
        description: "Diffuz est une plateforme gratuite et ouverte à tous, proposant de petites actions solidaires partout en France. Dans le cas d’un projet d’école en équipe avec l’entreprise Diffuz, nous devions réaliser une application dédiée aux lycéens offrant plusieurs défis solidaires.",
        link: "/diffuz",
        image: diffuzImage
    },
    {
        id: 4,
        title: "Maisons du Monde",
        subtitle: "Refonte du site internet",
        description: "Maisons du Monde est une enseigne de distribution, d'ameublement et de décoration française. Mon rôle consistait à réaliser des bandeaux, des landing pages et différents visuels pour leurs campagnes. Ce projet était une mission proposée par Maisons du Monde au sein de notre école.",
        link: "/maisonDuMonde",
        image: maisonDuMondeImage
    },
    {
        id: 5,
        title: "Restaurant Thaï Thaê",
        subtitle: "Site vitrine",
        description: "Récemment ouvert à Paris, ce restaurant familial a fait appel à mes services afin de leur proposer un site vitrine regroupant toutes les informations sur leur restaurant proposant des plats typiques de la Thaïlande.",
        link: "/thaiThae",
        image: thaiThaeImage
    },
]

export const PROJECT_DETAILS: ProjectsDetails[] = [
    {
        id: 0,
        title: "BDK Parfums",
        subtitle: "Refonte du site internet",
        description: "Actuellement, le site de BDK Parfums est un site one-page proposant beaucoup de contenus. La maison prévoit d'ajouter d'avantage de produits ce qui sollicitera le chargement de la page. L'objectif est de proposer une nouvelle interface pour le site BDK Parfums, elle doit être à l'image de la maison et doit proposer une page mettant en valeur les parfumeurs qui ont travaillé avec la marque. Le contenu du site proposera de nombreux produits qui devront être mieux référencer.",
        linkWeb: "https://xd.adobe.com/view/97940ac7-1965-4f46-b851-68b1c5f97f19-c2c7/",
        linkMobile: "https://xd.adobe.com/view/060552d7-d24f-4456-b61f-f4c3cd8d019a-5356/",
        imageDescription: bdkImage,
        imageMaquette: bdkMaquette,
        imageMaquettePlus: ""
    },
    {
        id: 1,
        title: "Bières cultes",
        subtitle: "Refonte du site internet",
        description: "Bières Cultes est une entreprise possédant des magasins de bières artisanales basées à Paris où les clients peuvent consommer sur place. Je devais proposer une nouvelle interface web et transformer le site actuel en un site e-commerce. Ne disposant pas d'une interface responsive pour le format mobile, j'ai repris toute la structure du site web pour le transformer en un site e-commerce regroupant différentes sections (produits, détails magasins, articles, blog).",
        linkWeb: "https://xd.adobe.com/view/6a2d151e-2ffd-4256-b245-cbc97a6e7a6d-3f7e/",
        linkMobile: "https://xd.adobe.com/view/0ae7f9f2-c206-484c-ab7e-b4fa3ba09ebc-5fb4/",
        imageDescription: biereCulteImage,
        imageMaquette: biereCulteMaquette,
        imageMaquettePlus: ""
    },
    {
        id: 2,
        title: "Si We Go",
        subtitle: "Application mobile",
        description: "L'application Si We Go propose divers exercices qui amélioreront le développement personnel et sensibiliseront les utilisateurs au stress dans leur milieu de travail. Cette application met en valeur différentes thématiques tout en s'inspirant des codes de la gamification afin de rendre ses exercices et ses actions plus ludiques et plus addictifs pour l'utilisateur. Des systèmes de récompenses, d'axe de progression, des articles sont également disponibles. L'application est un projet d'école de fin d'année.",
        linkWeb: "",
        linkMobile: "https://www.figma.com/proto/i0KCkVhYTmGoxGctKQZWGj/Si-Wee-Go?node-id=1%3A1590&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A1590&show-proto-sidebar=1",
        imageDescription: siwegoImage,
        imageMaquette: "",
        imageMaquettePlus: ""
    },
    {
        id: 3,
        title: "Diffuz",
        subtitle: "Application mobile",
        description: "Diffuz est une plateforme gratuite et ouverte à tous, proposant de petites actions solidaires partout en France. Il s’agit de la solution qui donne envie d’agir où qu’on soit et quand on veut. En renseignant sa ville et les associations qu’on souhaiterait aider, Diffuz propose plusieurs défis solidaires dans la joie et la bonne humeur. La plateforme offre l’opportunité aux français et aux acteurs de la solidarité de se rencontrer pour agir ensemble. Diffuz souhaitait appliquer ces différents défis solidaires à des lycées dans l’objectif de sensibiliser les jeunes aux problèmes écologiques. Ce travail était réalisé en équipe dont le but consistait à rendre l'application accessible pour les lycéens.",
        linkWeb: "",
        linkMobile: "https://www.figma.com/proto/qomVhUDRTFsEb16dxtUYgY/Diffuz_team?node-id=1117%3A466&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1117%3A466&show-proto-sidebar=1",
        imageDescription: diffuzImage,
        imageMaquette: diffuzMaquette,
        imageMaquettePlus: ""
    },
    {
        id: 4,
        title: "Maisons du Monde",
        subtitle: "Refonte du site internet",
        description: "Lors d'un projet d'école pour Maisons du Monde, ma mission était de créer des bandeaux pour les homes pages du site et des visuels pour les différentes campagnes ainsi que leurs réseaux sociaux.",
        linkWeb: "",
        linkMobile: "",
        imageDescription: maisonDuMondeImage,
        imageMaquette: maisonDuMondeMaquette,
        imageMaquettePlus: maisonDuMondeMaquettePlus
    },
    {
        id: 5,
        title: "Restaurant Thaï Thaê",
        subtitle: "Site vitrine",
        description: "Récemment ouvert à Paris, ce restaurant familial a fait appel à mes services afin de leur proposer un site vitrine regroupant toutes les informations sur leur restaurant proposant des plats typiques de la Thaïlande.",
        linkWeb: "",
        linkMobile: "",
        imageDescription: thaiThaeImage,
        imageMaquette: ThaiThaeMaquette,
        imageMaquettePlus: ""
    },
]

export const EXPERIENCE_DETAILS: ExperiencesDetails[] = [
    {
        id: 0,
        title: "BDK Parfums",
        description: "Mars 2019 - Janvier 2022 Ux / Ui Designer (alternance + CDD)",
        missions: ["Conception et recherche d’un nouveau  site web (web & mobile)", "Maquettes graphiques et interactives", "Animation sur After Effect", "Réalisation de packaging", "Création de contenu pour les réseaux sociaux", "Développement du compte Instagram (+50% en 2 ans)", "Animation et vente de parfums", "Logistique de commande e-shop", "Gestion du service après vente (e-shop)"],
    },
    {
        id: 1,
        title: "Nov’Impact",
        description: 'Octobre 2018 - Mars 2019 \n Assistant chef de projet digital (alternance)',
        missions: ["Gestion de projets digitaux ", "Rédaction de newsletters", "Création de maquettes pour le site", "Recherche utilisateur"],
    },
    {
        id: 2,
        title: "Fichit",
        description: "Mai - Juillet 2017 Graphiste (stage)",
        missions: ["Réalisation de pictogrammes pour le projet «Langage universel»", "Proposition de maquette pour le site Fichit.com"],
    },


]

export const LogsEmailJs = {
    serviceId: "service_uvn4grp",
    templateKey: "template_cp6fd39",
    publicKey: "CbsFM7oD13rggvHnL"
}