import { useWindowScroll } from '@mantine/hooks';
import { ActionIcon, Affix, Transition, useMantineColorScheme } from '@mantine/core';
import 'remixicon/fonts/remixicon.css'

export default function ScrollToTop() {
    const [scroll, scrollTo] = useWindowScroll();

    const { colorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    return (
        <Affix position={{ bottom: 20, right: 20 }}>
            <Transition transition="slide-up" mounted={scroll.y > 0}>
                {(transitionStyles) => (
                    <ActionIcon
                        color={dark ? 'gray' : 'dark'}
                        radius="xl"
                        variant="filled"
                        size="xl"
                        style={transitionStyles}
                        onClick={() => scrollTo({ y: 0 })}
                    >
                        <i className='ri-arrow-up-line ri-xl' />
                    </ActionIcon>
                )}
            </Transition>
        </Affix>
    );
}