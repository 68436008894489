import { Center, Container, Loader, Text, Title, useMantineColorScheme } from "@mantine/core";

type LoadingPageProps = {
    noAppLayout?: boolean;
};

export default function LoadingPage({
    noAppLayout = false,
}: LoadingPageProps) {

    const { colorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    const Animation = (
        <Loader
            color={dark ? 'gray' : 'dark'}
            style={{ width: 100, height: 100, margin: "auto" }}
        />
    );

    if (noAppLayout) {
        return <Center>{Animation}</Center>;
    }

    return (
        <div style={{ backgroundColor: dark ? "#1A1B1E" : "#F5EFE3" }}>
            <Container
                style={{ paddingTop: "15%", height: "95vh", textAlign: "center" }}
            >
                {Animation}
            </Container>
        </div>
    );
}