import { useState } from "react";
import {
    ColorScheme,
    ColorSchemeProvider,
    MantineProvider,
} from "@mantine/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import LoadingPage from "./components/Common/LoadingPage";
import "./App.css";
import { PROJECT_DETAILS } from "./components/utils/const";
import ScrollToTop from "./components/Common/ScollTotop";

const Home = lazy(() => import("./components/Home/Home"));

const BDK = lazy(() => import("./components/DetailsProject/ProjectPage"));

const BiereCulte = lazy(
    () => import("./components/DetailsProject/ProjectPage")
);

const SiWeGo = lazy(() => import("./components/DetailsProject/ProjectPage"));

const Diffuz = lazy(() => import("./components/DetailsProject/ProjectPage"));

const MaisonDuMonde = lazy(
    () => import("./components/DetailsProject/ProjectPage")
);

const ThaiThae = lazy(() => import("./components/DetailsProject/ProjectPage"));

const Contact = lazy(() => import("./components/Contact/ContactPage"));

function App() {
    const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

   

    

    return (
        <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
        >
            <MantineProvider
                theme={{
                    fontFamily: "Roboto, sans-serif",
                    colorScheme,
                    headings: {
                        fontFamily: "Lato, sans-serif",
                        fontWeight: "bold",
                    },
                }}
                withGlobalStyles
                withNormalizeCSS
            >
                <BrowserRouter>
                    <Suspense fallback={<LoadingPage />}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route
                                path="/bdk"
                                element={
                                    <BDK projectDetails={PROJECT_DETAILS[0]} />
                                }
                            />
                            <Route
                                path="/biereCulte"
                                element={
                                    <BiereCulte
                                        projectDetails={PROJECT_DETAILS[1]}
                                    />
                                }
                            />
                            <Route
                                path="/siwego"
                                element={
                                    <SiWeGo
                                        projectDetails={PROJECT_DETAILS[2]}
                                    />
                                }
                            />
                            <Route
                                path="/diffuz"
                                element={
                                    <Diffuz
                                        projectDetails={PROJECT_DETAILS[3]}
                                    />
                                }
                            />
                            <Route
                                path="/maisonDuMonde"
                                element={
                                    <MaisonDuMonde
                                        projectDetails={PROJECT_DETAILS[4]}
                                    />
                                }
                            />
                            <Route
                                path="/thaiThae"
                                element={
                                    <ThaiThae
                                        projectDetails={PROJECT_DETAILS[5]}
                                    />
                                }
                            />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
                <ScrollToTop />
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;
